import axios from "axios";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { Col, Row, Form, Container, Modal } from "react-bootstrap";
import { ContactButton, InputWithIcon } from "../../../Styled/CustomElements";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  Box,
  LabelStyled,
  LazyImage,
  TextStyled,
} from "../../../Styled/Elements";
import {
  Message,
  Phone,
  SendArrow,
  SuccessLogo,
  User,
  Work,
} from "../../../Styled/AllImages";

const ContactForm = () => {
  const mapLocation =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12201.369174426525!2d45.3301478!3d40.1346585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403fe55377cdf491%3A0x6039ca70f13d4c4a!2sVaghashen%2C%20Armenia!5e0!3m2!1sen!2s!4v1707823318768!5m2!1sen!2s"; const { t, i18n } = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const options = useMemo(() => countryList().getData(), []);


  const getForm = async () => {
    try {
      console.log(formData);
      const response = await axios.post("", { ...formData, language: i18n.language, country: formData.country.label })
      if (!response.data) {
        throw new Error('Response error plz check the response');
      }
      else {
        handleShowSuccessModal();
        setFormData({
          name: "",
          country: "",
          phone: "",
          project_type: "",
          project_idea: "",
        });
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const [Errors, setErrors] = useState({
    name: "",
    country: "",
    phone: "",
    project_type: "",
    project_idea: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    country: "",
    phone: "",
    project_type: "",
    project_idea: "",
  });
  const FormHandle = (e) => {
    console.log("e is", e);
    const name = e.target.name;
    const value = e.target.value;

    setFormData((list) => {
      return { ...list, [name]: value };
    });
    setErrors((errors) => {
      return { ...errors, [name]: "" };
    });
  };

  const validateForm = () => {
    const errors = {};
    if (formData.name.trim() === "") {
      errors.name = t("Name_is_required");
    }
    if (formData.name.trim() === "") {
      errors.country = t("country_is_required");
    }
    if (formData.phone.trim() === "" || !isValidPhone(formData.phone)) {
      errors.phone = t("Invalid_phone_number");
    }
    if (formData.project_type.trim() === "") {
      errors.project_type = t("subject_is_required");
    }
    if (formData.project_idea.trim() === "") {
      errors.project_idea = t("message_is_required");
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const isValidPhone = (phone) => {
    const phoneRegex = /^\d+$/;
    return phoneRegex.test(phone);
  };
  const handleSubmit = useMemo(() => {
    return (e) => {
      e.preventDefault();
      if (validateForm()) {
        getForm();
        console.log(formData);
      }
    };
  }, [formData]);


  return (
    <ContacFormWapper>
      <Container>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-5">
                <Col md={12} className="">
                  <LabelStyled className="py-2">{t("Name")}</LabelStyled>
                  <InputWithIcon
                    type="text"
                    name="name"
                    value={formData.name}
                    placeholder={t("Name")}
                    icon={User}
                    onChange={FormHandle}
                  />
                  <TextStyled className="error-message">
                    {Errors.name}
                  </TextStyled>
                </Col>
                <Col md={12} className="">
                  <LabelStyled className="py-2">{t("Country")}</LabelStyled>
                  <Select
                    name='country'
                    options={options}
                    value={formData.country}
                    onChange={(value) => setFormData({
                      ...formData,
                      country: value
                    })}
                    placeholder={t("Select_country")}
                  />
                  <TextStyled className="error-message">
                    {Errors.country}
                  </TextStyled>
                </Col>

                <Col md={12} className="">
                  <LabelStyled className="py-2">{t("Phone")}</LabelStyled>
                  <InputWithIcon
                    type="text"
                    name="phone"
                    value={formData.phone}
                    placeholder={t("Phone")}
                    icon={Phone}
                    onChange={FormHandle}
                  />
                  <TextStyled className="error-message">
                    {Errors.phone}
                  </TextStyled>
                </Col>
                <Col md={12} className="">
                  <LabelStyled className="py-2">
                    {t("Project_Type")}
                  </LabelStyled>
                  <Box className="project-field">
                    <Work />
                    <select
                      className="project-type"
                      name="project_type"
                      value={formData.project_type}
                      onChange={FormHandle}
                    >
                      <option value="type" selected disable hidden>
                        {t("Project_Type")}
                      </option>
                      <option value="Brand">{t("Brand_Development")}</option>
                      <option value="Marketing">
                        {t("Digital_Marketing")}
                      </option>
                      <option value="Website">
                        {t("Website_Development")}
                      </option>
                      <option value="Mobile-App">
                        {t("Website_Development")}
                      </option>
                      <option value="Design">{t("UX_UI_Design")}</option>
                      <option value="Design">{t("Graphic_Design")}</option>
                    </select>
                  </Box>
                  <TextStyled className="error-message">
                    {Errors.project_type}
                  </TextStyled>
                </Col>

                <Col md={12} className="">
                  <LabelStyled className="py-2">{t("Message")}</LabelStyled>
                  <Box className="textarea-box">
                    <Box className="text-icon">
                      <Message />
                    </Box>
                    <textarea
                      cols=""
                      rows="6"
                      name="project_idea"
                      value={formData.project_idea}
                      placeholder={t("Message")}
                      onChange={FormHandle}
                    ></textarea>
                    <TextStyled className="error-message">
                      {Errors.project_idea}
                    </TextStyled>
                  </Box>
                </Col>
                <ContactButton
                  className="btn-gradient-bg contact-btn"
                  content="end"
                  icon={SendArrow}
                  label={t("Send")}
                />
              </Row>
            </Form>
          </Col>

        </Row>
      </Container>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <LazyImage src={SuccessLogo} />
          <TextStyled>{t("Message_Sent_successfully")}</TextStyled>
        </Modal.Body>
        {/* <Modal.Footer>
          <ContactButton
            className="btn-gradient my-1"
            content="end"
            label={t("Close")}
            onClick={handleCloseSuccessModal}
          />
        </Modal.Footer> */}
      </Modal>
    </ContacFormWapper>
  );
};
const ContacFormWapper = styled.div`
  iframe {
    width: 100%;
    height: 83%;
    margin-top: 25px;
  }
  .contact-btn > button {
    flex-direction: row-reverse;
    color: var(--white);
    width: 85px;
    z-index: 100;
  }
`;

export default ContactForm;

import React, { useEffect } from 'react'
import { Box } from './Styled/Elements';
import Header from './Components/header/Header';
import Footer from './Components/footer/Footer';
import { privateRoute } from './Router/PrivateRoute';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import i18n from './Languages/INEXT';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: 'smooth' })
  }, [location]);

  const [cookies, setCookie] = useCookies(['lang']);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCookie('lang', lng, { path: '/' }); // Set cookie with the language preference
  };

  return (
    <>
      <Box>
        <Header />
        <Routes>
          {privateRoute.map((list, key) => (
            <Route to={key} {...list} />
          ))}
        </Routes>
        <Footer />
      </Box>
    </>
  );
}

export default App;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import arabic from "./arabic.json";
import english from "./english.json";
import Cookies from "js-cookie";
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: english.translation,
      },
      ar: {
        translation: arabic.translation,
      },
    },
    lng: Cookies.get("lang") || "en",
    fallbackLng: "en", // Fallback language if a translation is missing
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      order: ["Cookies", "navigator"],
      caches: ["Cookies"],
    },
  });

export default i18n;

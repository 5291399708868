import styled from "styled-components";

export const ProjectWapper = styled.section`

.project-digital{
    position: relative;
    background-color: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 70px 20px 0px 30px;
    &:after {
        box-shadow: 20px 0 25px -20px rgba(88, 108, 154, 0.5) inset;
        content: " ";
        height: 100%;
        position: absolute;
        top: 0;
        right: -10px;
        width: 20px;
    }
    & .project-bg{
        position: absolute;
        top: 0;
        right: 5%;
    }
}
.project-other {
    padding: 70px 18px 0px 25px;
    background-color: white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.project-wapper{
    margin: 20px 0;
}
.project-content{
    display: flex;
    align-items: flex-start;
    gap: 0px;
    &>div{
        width:100%;
    }
}
.compaire{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    &>div{
        width:100%;
    }
}
`
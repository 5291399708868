import styled from "styled-components";

export const ContactWapper = styled.section`
    position:relative;
    background:var(--white);

    .location-card{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
`
import styled from "styled-components";
export const BannerWapper = styled.section`
  .banner-wapper-card {
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 100px;
  }
  .banner-card {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .banner-text-card {
    display: flex;
    flex-direction: column;
  }
  .bannerImg {
    text-align: end;
    position: relative;
  }
  .banner-text {
    width: 70% !important;
  }

  .slick-dots li button:before {
    width: 25px !important;
    height: 3px !important;
    border: none !important;
    border-radius: 0px !important;
    background: var(--themeTextGradient) !important;
    font-size: 0px !important;
  }
  .popup,
  .arabic-popup {
    position: absolute;
    z-index: 10;
    animation: drop-in 1s ease 2500ms backwards,
      pop-animate 3s ease 2700ms infinite;
  }
  @keyframes drop-in {
    0% {
      opacity: 0;
      width: 0px;
    }
    100% {
      opacity: 1;
      width: 237px;
    }
  }
  @keyframes pop-animate {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  .arabic-popup {
    position: absolute;
    z-index: 10;
    display: none;
  }
  .pop-mobile {
    top: 90px;
    left: 0;
  }
  .pop-social {
    top: 70px;
    bottom: 0;
    right: -30px;
    margin: auto;
  }
  .pop-website {
    bottom: 60px;
    left: -20px;
  }
  .typing-effect {
    white-space: nowrap;
    overflow: hidden;
    animation: typing 3s steps(50, end);
    transition: all 0.4s ease;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 70%;
    }
  }
`;

import React from 'react'
import Tilt from 'react-vanilla-tilt'
import ServiceCard from './ServiceCard'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { serviceCardData } from '../../sliderData/Data'
import { ServiceCardBg } from '../../../Styled/AllImages'
import { ServiceCardWapper, ServicesWrapper } from './Styled'
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from '../../../Styled/Elements'


const Services = () => {
    const option = {
        reverse: false,  // reverse the tilt direction
        max: 45,     // max tilt rotation (degrees)
        perspective: 1000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale: 1.5,      // 2 = 200%, 1.5 = 150%, etc..
        speed: 300,    // Speed of the enter/exit transition
        transition: true,   // Set a transition on enter/exit.
        axis: null,   // What axis should be disabled. Can be X or Y.
        reset: true,   // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
        glare: false,   // if it should have a "glare" effect
        "max-glare": 1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false   // false = VanillaTilt creates the glare elements for you, otherwise
        // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
    }
    const { t } = useTranslation()
    return (
        <ServicesWrapper className='container-fluid common-space' id='services'>
            <Container>
                <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3'>
                    <SpanStyled className='subHeading' color='var(--textGrey)' size='18px'>{t('WHAT_WE_DO')}</SpanStyled>
                    <HeadingStyled lh='45px' className='themeTextGradient'>{t('Crafting_Excellence')}</HeadingStyled>
                </Box>
                <ServiceCardWapper>
                    <Row className='service-card-wrapper common-space'>
                        {
                            serviceCardData.map((list, key) => (
                                <Col lg='4' md='6' sm='12' key={key}>
                                    <Tilt className='title-card' options={{ scale: 1.5, max: 45 }}>
                                        <Box className='service-card'>
                                            {/* <LazyImage className='service-card-bg' src={ServiceCardBg} /> */}
                                            <Box className='service-card-content'>
                                                {list.img}
                                                <HeadingStyled color='var(--themeColor)' size='18px' weight='normal'>{t(list.heading)}</HeadingStyled>
                                                <TextStyled >{t(list.text)}</TextStyled>
                                            </Box>
                                        </Box>
                                    </Tilt>
                                </Col>
                            ))
                        }
                    </Row>
                </ServiceCardWapper>
                <ServiceCard />
            </Container>
        </ServicesWrapper>
    )
}


export default Services

import React from 'react';
// import ReactPlayer from 'react-player';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import { Video } from '../../../Styled/AllImages';
import { Modal } from 'react-bootstrap';

const VideoPlayer = ({ show, handleClose }) => {
    return (
        <Modal size='lg' show={show} onHide={handleClose} className='video-bg'>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Player
                    // className='videocontent'
                    playsInline
                    poster="/assets/poster.png"
                    src={Video}
                />
                {/* <ReactPlayer url={Video} controls className='videocontent' /> */}
                {/* <video id="VisaChipCardVideo" class="video videocontent" controls="">
                    <source src={Video} type="video/mp4">
                </video> */}
            </Modal.Body>
        </Modal>
    )
}

export default VideoPlayer

import styled from "styled-components";

export const FooterWapper = styled.footer`
position:relative;
padding: 40px 0 20px;
background: var(--themeColor);
width:100%;
.footer-list{
position:relative;
display:flex;  
align-items: center;
gap: 35px;

& a{
    position:relative;
    color:var(--white);
    padding-bottom: 5px;
    &:hover, &.active{
        background: var(--themeTextGradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &::before, &.active::before{
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 15px;
            height: 3px;
            background: var(--themeTextGradient);
        }
    }
}
.fowl{
    cursor:pointer;
}
}

`
import React from 'react'
import ContactForm from './ContactForm'
import { ContactWapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../Styled/Elements'
import Map from './Map'


const Contact = () => {
    const { t } = useTranslation();

    return (
        <ContactWapper id='contact'>
            <Container>
                <Row>
                    <Box className='text-center  my-5'>
                        <HeadingStyled className='themeTextGradient' color='var(--darkColor)'>Contact Us</HeadingStyled>
                        <TextStyled>We're proud to serve you. Explore our network of branches and discover how we can bring our expertise to your doorstep.</TextStyled>
                    </Box>
                </Row>
                <Map />
                <Row className='rows-gap common-space'>
                    <Col lg={12} md={12} className='contact-Us'>
                        <Box className='contect-with-us'>
                            <ContactForm />
                        </Box>
                    </Col>
                </Row>
            </Container>
            {/* <ContactForm /> */}
        </ContactWapper>
    )
}


export default Contact

import styled from 'styled-components'

export const ServicesWrapper = styled.section`
.main-heading::before{
    content: '';
    position: absolute;
    left: 10px;
    width: 70px;
    height: 4px;
    background: var(--gradientBlue);
    bottom: -5px;
}
.rtl{
    &.main-heading::before{
        right: 15px;
        left:auto;
    }
} 
`
export const ServiceCardWapper = styled.div`
.service-card{
    transform-style: preserve-3d;
    position:relative;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    height: 280px;
    transition: all 0.5s ease-in-out;
    &>span{
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 20px;
        overflow: hidden;
    }
    &:hover{
        box-shadow: 0px 5px 20px 0px rgb(227 227 227 / 85%);
    }
}
.service-card-wrapper{
    row-gap: 30px;
}
.service-card-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

`
export const ServiceCardBox = styled.div`
.service-card-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
}
.service-card-content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

`
import styled from 'styled-components'


export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    transition: all 0.5s cubic-bezier(.77,0,.18,1);
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 35px;
    }
    .nav-bar-list a{
        color:var(--themeColor);
        padding-bottom: 12px;
        position: relative;
        font-size:14px;
        font-family:var(--medium);
        text-transform: uppercase;
        &:hover, &.active, &:focus{
            background: var(--themeTextGradient);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &::before, &.active::before{
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 15px;
                height: 3px;
                background: var(--themeTextGradient);
            }
        }
        
    }
    .logo{
        cursor:pointer;
        transition: all 0.5s cubic-bezier(.77,0,.18,1);
        svg{
            width:180px;
        }
    }
    &.sticky{
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
        background: var(--white);
        box-shadow: 0px 5px 15px #dbdbdbd6;
        transition: all 0.5s cubic-bezier(.77,0,.18,1);
        z-index: 200;
        .logo svg{
            transition: all 0.5s cubic-bezier(.77,0,.18,1); 
        }
    }
    .toggleBtn{
        display: none;
    }
}
`
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { BannerWapper } from './Styled';
import VideoPlayer from './VideoPlayer';
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { bannerDataConfig } from '../../sliderData/Data'
import { ContactButton } from '../../../Styled/CustomElements'
import { ArabicMobilePop, ArabicSocialPop, ArabicWebsitePop, MobilePop, SocialInstagram, SocialLinkdin, SocialPop, SocialTwitter, VedioBtn, WebsitePop, WhatsappBtn } from '../../../Styled/AllImages'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../Styled/Elements'


const Banner = () => {
    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };
    const WhatsApp = () => {
        const phoneNumber = '+923164000186';
        const message = 'Hello, torce Networking';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const SliderSetting = {
        dots: true,
        autoplay: true,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplaySpeed: 5000,
    }
    return (
        <>
            <BannerWapper className='container-fluid banner-bg' id='home'>
                <Container className='h-100'>
                    <Box className='banner-wapper-card'>
                        <Slider {...SliderSetting}>
                            {
                                bannerDataConfig.map((list, key) => (
                                    <Row key={key} className='banner-card'>
                                        <Col lg={6} md={12} className='banner-text-card'>
                                            <Box className='d-flex flex-column text-uppercase'>
                                                <SpanStyled lh='40px' className='typing-effect sub-heading' color='var(--themeColor)'>{t(list.subHeading)}</SpanStyled>
                                                <HeadingStyled lh='60px' size='70px' className='typing-effect heading' color='var(--themeColor)'>{t(list.heading)}</HeadingStyled>
                                                <SpanStyled lh='50px' className='typing-effect themeTextGradient TextGradientFont resp' size='54px' color='var(--white)' spacing='10px'>{t(list.heading2)}</SpanStyled>
                                                <TextStyled className='banner-text'>{t(list.des)}</TextStyled>
                                            </Box>
                                            <Box className='d-flex align-items-center gap-3 mx-1'>
                                                <ContactButton className='btn-gradient-bg' icon={WhatsappBtn} label={t(list.label)} onClick={WhatsApp} />
                                                <ContactButton className='btn-gradient' icon={VedioBtn} label={t(list.vedio)} onClick={openModal} />
                                                <VideoPlayer show={showModal} handleClose={closeModal} />
                                            </Box>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <Box className="bannerImg">
                                                <img className='animate popup pop-mobile' src={MobilePop} alt='img' />
                                                <img className='animate popup pop-website' src={WebsitePop} alt='img' />
                                                <img className='animate popup pop-social' src={SocialPop} alt='img' />
                                                {list.img}
                                                <img className='animate arabic-popup arabic-pop-mobile' src={ArabicMobilePop} alt='img' />
                                                <img className='animate arabic-popup arabic-pop-website' src={ArabicWebsitePop} alt='img' />
                                                <img className='animate arabic-popup arabic-pop-social' src={ArabicSocialPop} alt='img' />
                                            </Box>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Slider>
                    </Box>
                    <Box className='social-link'>
                        <Box><SocialTwitter /></Box>
                        <Box><SocialInstagram /></Box>
                        <Box><SocialLinkdin /></Box>
                    </Box>
                </Container>
            </BannerWapper>
        </>
    )
}


export default Banner

import React from 'react'
import Slider from 'react-slick'
import { PortfolioWapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Container, Row } from 'react-bootstrap'
import { PortfolioDataConfig } from '../../sliderData/Data'
import { ArrowLeft, ArrowRight } from '../../../Styled/AllImages'
import { Box, TextStyled, SpanStyled, HeadingStyled } from '../../../Styled/Elements'

const Portfolio = () => {
    const { t } = useTranslation();
    const settings = {
        arrows: true,
        infinite: true,
        centerPadding: "0px",
        speed: 500,
        slidesToShow: 3,
        slidesPerRow: 1,
        rows: 2,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesPerRow: 1,
                    rows: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 1,
                    rows: 1,
                }
            }
        ]
    }
    return (
        <PortfolioWapper id='portfolio'>
            <Container>
                <Row>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-5'>
                        <SpanStyled className='subHeading' color='var(--textGrey)' size='18px'>{t('Discover')}</SpanStyled>
                        <HeadingStyled lh='45px' className='themeTextGradient'>{t('Portfolio_heading')}</HeadingStyled>
                        <TextStyled className='my-3'>{t('Portfolio_content')}</TextStyled>
                    </Box>
                </Row>

                <Box className=''>
                    <Slider {...settings}>
                        {
                            PortfolioDataConfig.map((list, key) => (
                                <Box key={key}>
                                    {list.img}
                                </Box>
                            ))
                        }
                    </Slider>
                </Box>
            </Container>
        </PortfolioWapper>
    )
}


export default Portfolio
